.searchBox input {
  background: #fff !important;
  left: 0 !important;
  color: #000;
  width: 100%;
  outline: 0;
  transform: rotateZ(0);
  min-width: 14em;
  min-height: 2.71428571em;
  display: inline-block;
  padding: 0.78571429em 2.1em 0.78571429em 1em;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, width 0.1s ease;
}
.searchBox input::-webkit-input-placeholder {
  font-size: 11px;
  color: #ccc;
}
.title {
  font-size: 10px;
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #999;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.smallTitle {
  font-size: 12px;
  margin-bottom: 5px;
  color: #999;
  text-transform: uppercase;
}
.companiesTag {
  display: flex;
  width: fit-content;
  border-radius: 5px;
  background: #ebebeb;
  font-size: 12px;
  color: #666666;
  padding: 5px;
  align-items: center;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}
.companiesTag p {
  padding-right: 10px;
  margin-bottom: 0;
  line-height: 1rem;
}
.companiesTag span {
  width: 20px;
}
.growthrate .ui[class*="right labeled"].input > input {
  border-top-right-radius: 0.28571429rem !important;
  border-bottom-right-radius: 0.28571429rem !important;
  border-right-color: rgba(34, 36, 38, 0.15) !important;
}
.growthrate .label.label {
  border: none !important;
  background: #f5f6f8 !important;
}
.insightplus .showcompanyList {
  padding: 0 !important;
  margin-top: 30px !important;
}
.insightplus .showcompanyList h4 {
  margin: 0;
}
