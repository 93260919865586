body {
  background: #fefdfd;
  font-family: "Lato", sans-serif;
}

.bold {
  font-weight: 500;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.marginLeft30{
  margin-left: 30px;
}

.marginTop40{
  margin-top: 40px;
}


.ml5 {
  margin-left: 5px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  margin: 0 auto;
  display: flex;
}

.pLeft100 {
  padding-left: 100px;
}

.mT25 {
  margin-top: 25px;
}
.mt0{
  margin-top: 0 !important;
}

.mT15 {
  margin-top: 15px;
}

.outerContainer {
  padding-left: 100px;
  padding-right: 40px;
}

.w20 {
  width: 20%;
}
.w25 {
  width: 25%;
}
.w30 {
  width: 30% !important;
}
.w40 {
  width: 40%;
}
.w50 {
  width: 50%;
}

.pl20{
  padding-left: 20px !important;
}
.w60 {
  width: 60%;
}
.w70 {
  width: 70%;
}
.w80 {
  width: 80%;
}
.w90 {
  width: 90%;
}
.w100 {
  width: 100%;
}

.mT15{
  margin-top: 15px;
}

.filterBox {
  width: 350px;
  padding: 40px 0;
  min-height: 100vh;
  background-color: #f5f6f8;
  border-right: 1px solid #efe9ed;
}
.ui.button {
  border-radius: 0 !important ;
}
.plr {
  padding: 0 20px;
}

/* colors */
.primaryColor {
  color: #718486;
}

.h100 {
  height: 100%;
}
.pd20 {
  padding: 20px;
}

.overflowAuto {
  overflow: auto;
}

.flex {
  display: flex;
}

.flexWrap {
  flex-wrap: wrap;
}

.flexSpaceBetween {
  justify-content: space-between;
}
.mrRight10 {
  margin-right: 10px;
}
.mrBottom20 {
  margin-bottom: 20px;
}

.w300px {
  width: 300px;
}


.showScrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  background-color: #f5f5f5;
}

.showScrollbar::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}

.showScrollbar::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ffc82d;
}

.mt40 {
  margin-top: 30px;
}

.w120 {
  width: 120px;
}
.ml10 {
  margin-left: 10px;
}

.w220 {
  width: 220px !important;
}
.w100px {
  width: 100px !important;
}
.w160 {
  width: 160px !important;
}
.w250 {
  width: 250px !important;
}
.w280 {
  width: 280px !important;
}
.positionRelative {
  position: relative;
}

.lable {
  font-size: 12px;
  color: #999;
  text-transform: uppercase;
  margin-bottom: 5px;
  display: block;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.filter input {
  background: #ebebeb !important;
  left: 0 !important;
  color: #000;
}

.submitButton {

  background: #212121 !important;
  text-transform: uppercase !important;
  color: #fff !important;
}

.height100vh {
  height: 100vh;
}
.overflowX {
  overflow-x: hidden;
}
.overflowY {
  overflow-y: hidden;
}


 /* autosuggest__suggestions */
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container{
  border-bottom-left-radius: 0;
  height: 280px;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  position: absolute;
  top: 51px;
  width: 300px;
  margin: 0;
  padding: 0;
 background: #fff;
  border-radius: 5px;
  list-style-type: none;
  border: none!important;
  box-shadow: none!important;
  cursor: text;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255,255,255,0);
  padding: inherit;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  overflow-y: scroll;
}



.react-autosuggest__suggestions-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  background-color: #f5f5f5;
}

.react-autosuggest__suggestions-container::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}

.react-autosuggest__suggestions-container::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ffc82d;
}

.react-autosuggest__suggestions-list{
  padding: 0;
  margin: 0;
  list-style: none;
}
.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 4px 20px; font-family: "Lato", sans-serif;
  font-size: 12px;font-weight: 600;
  line-height: 1.3rem;
  display: inline-block;
  width: 100%;

}

.react-autosuggest__suggestion:hover {
background-color: #999;
color: #fff;
}

.react-autosuggest__suggestion--focused {
  background-color: #ddd;    color: #fff;
}

.errorMess{
  font-size: 11px;
  color: red;
  padding: 5px 0;
  text-align: left;
}

.emptyTitle{
  font-size: 18px;
  color: #999;
  display: flex;
 
}
.flexEnd{
  justify-content: flex-end;
}
.showDateTitel{
  color: #718496;
  font-size: 24px;
}
.filterTitleInfo{
  color: #718496;
  font-size: 24px;
  margin-bottom: 25px;
  cursor: pointer;
}
.emptyTitle svg{margin-right: 10px ;}

.showfilterBox{
  margin-bottom: 40px;
  padding: 20px 0;
  border-bottom: 1px solid #f5f6f8;
}

.dropdownTrigger{
  float: right;
  margin-top: 10px;
  
  text-align: right;
 
}
.dropdownTrigger .icon{
  background: #fff !important;
    border: 1px solid #ccc !important;font-size: 12px !important;
    color: #ccc !important;
    padding: 5px 10px 5px 0px !important;
}

.dropdownTrigger .icon i{
  background: #fff !important;
border: none !important;

}
.popcontent ul{
  padding: 0 20px;
  margin: 0;
  list-style-type: none;
}

.popcontent ul li{
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 0px;
  
}
.popcontent ul li svg{
 margin-right: 10px;
  
}
.error{
  text-align: left;
  font-size: 12px;
  color: red;
}

.smallInput input{
  width: 100px;
  background: #fff !important;
}

.favourites{ 
  margin-top: 20px;
  padding: 10px 10px 10px 40px;
}
.favourites .showfilterBox{
  margin-bottom: 40px;
  padding: 20px;
  border: 1px solid #ccc;
}

.favourites .filterTitleInfo{
color: #718496;
text-decoration: underline;
}

.favourites .showDateTitel{
  border:none;
  color: #718496;
  }
  
  .favourites .popcontent ul{
width: 85px !important;
padding: 0 !important;
  }
  .favourites .popcontent ul li{
    text-transform: uppercase !important;
      }
      .popcontent ul li:hover{
       color: #ffc82d !important;
          }
  .favourites .showDateTitel .dropdownTrigger{
    margin-top: -10px;
  }
  .favourites .showDateTitel .icon{
  border: none !important;
  }
  .favouritesTitle{
    display: flex;
    text-transform: uppercase;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 16px;
    color: #999;
  }

  .dropdownTriggerHeader .icon{
    border: none !important;
    background: none !important;
    

    }
    .dropdownTriggerHeader button{
      border: none !important;
      background: none !important;
      width: 95px;
      font-weight: 600;
  
      }
    .dropdownTriggerHeader{
      margin-top: 0px;
      background-color: none;
    }

  .pointer{
    cursor: pointer;
  }
  label span{
    color: red;
  }

  .lodding{
    margin-top: 40px;
    opacity: 0.5;
    width: 100%;
    
   
  }
  
  .lodding .innerLodding{
    display: flex;
 align-items: baseline;
  }

  .lodding .innerLodding .segment{
    flex: 50%;
    padding: 10px;
    margin-bottom: 50px;
    margin-right: 50px;
  }
  .lodding img{
    height: 30px;
    width: 100%;
    margin-bottom: 10px;
  }

  .iconDate {
   background-image: url('./media/date.png') !important;
   background-position: 95% 50%!important;
   background-repeat: no-repeat !important;
   background-size: 14% !important;
}
  

.favouriteLeft .filterBox{
  background: none !important;
}

.favouriteLeft .listPanal{
  margin-top: 20px;
}

.colorfff{
  color: #fff !important;
}

.overLayer .ui.segment{
  width: 150px;
  box-shadow: none;
  background: none !important;    border: none;
}
.overLayer .ui.inverted.dimmer{
  background: none !important;
}

.overLayer .ui.inverted.dimmer .ui.loader:after{
  border-color: #fff transparent transparent;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}