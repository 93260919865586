.showcompanyList {
  margin: 60px 0 10px 0;
}
.showcompanyList h4 {
  margin: 0;
}
.showcompanyList h4 {
  margin-bottom: 0px;
  text-transform: uppercase;
  color: #999;
}
.showcompanyList h4 span {
  color: #000;
}
.showcompanyList p {
  font-size: 11px;
  color: #999;
}
.showcompanyList input {
  background: #ebebeb !important;
  border-radius: 0 !important;
  border: 1px solid #f5f6f8 !important;
}
.showcompanyList .listPanal {
  max-height: 300px;
  background: #ebebeb;
  padding: 10px 6px;
  border-radius: 5px;
}
.showcompanyList .listItem {
  display: flex;
  margin-top: 6px;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 12px;
  line-height: 1rem;
}
.showcompanyList .listItem .name {
  width: 90%;
  padding: 4px 10px;
  display: inline-block;
}
.showcompanyList .listItem span {
  padding: 4px 10px;
}
.showcompanyList .active {
  background: #fff !important;
  color: #000 !important;
}
.runReports {
  background: #ffc82d !important;
  text-transform: uppercase !important;
  color: #000 !important;
}
