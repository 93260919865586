

/* Table CSS */

  table {
    border-spacing: 0;
    border: 1px solid rgba(34,36,38,.15);
    margin-top: 20px;
    border-collapse: collapse;
    width: 100%;
    tr {
      th,
      td {
        margin: 0;
        padding: 0.5rem;
        border-bottom: 1px solid rgba(34,36,38,.15);
        border-right: 1px solid rgba(34,36,38,.15);
        min-width: 125px;
      }
      > :first-child {
        max-width: max-content;
        min-width: 40px;
        text-align: center;
      }
    }
  }
  .p-5 {
    padding: 20px;
  }
  .p-2{
    padding: 8px;
  }
  .border{
    border: 1px solid rgba(34,36,38,.15);
  }
  .rounded{
    border-radius: 4px;
  }
  .filter-input{
    border: 1px solid rgba(34, 36, 38, .15);
    border-radius: 4px;
    padding: 2px 4px;
    margin-top: 4px;
    width: 100%;
  }

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem* var(--tw-space-x-reverse));
  margin-left: calc(0.5rem* calc(1 - var(--tw-space-x-reverse)));
}

.gap-1 {
  gap: 0.25rem;
}
.gap-2 {
  gap: 0.5rem;
}
.items-center {
  align-items: center;
}
.mt-5, .ui.button.mt-5 {
  margin-top: 1.25rem;
}
.pagination button{
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 4px;
  cursor: pointer;
}

.pagination select {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 4px;
  padding: 2px 4px;
}
.overflow-auto {
  overflow: auto;
}
.text-gray-400{
  color: rgb(156 163 175);
}
.justify-between {
  justify-content: space-between;
}