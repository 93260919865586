@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: "Lato", sans-serif, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Lato", sans-serif, sans-serif;
}


body {
  background: #fefdfd;
  font-family: "Lato", sans-serif;
}

.bold {
  font-weight: 500;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.marginLeft30{
  margin-left: 30px;
}

.marginTop40{
  margin-top: 40px;
}


.ml5 {
  margin-left: 5px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  margin: 0 auto;
  display: flex;
}

.pLeft100 {
  padding-left: 100px;
}

.mT25 {
  margin-top: 25px;
}
.mt0{
  margin-top: 0 !important;
}

.mT15 {
  margin-top: 15px;
}

.outerContainer {
  padding-left: 100px;
  padding-right: 40px;
}

.w20 {
  width: 20%;
}
.w25 {
  width: 25%;
}
.w30 {
  width: 30% !important;
}
.w40 {
  width: 40%;
}
.w50 {
  width: 50%;
}

.pl20{
  padding-left: 20px !important;
}
.w60 {
  width: 60%;
}
.w70 {
  width: 70%;
}
.w80 {
  width: 80%;
}
.w90 {
  width: 90%;
}
.w100 {
  width: 100%;
}

.mT15{
  margin-top: 15px;
}

.filterBox {
  width: 350px;
  padding: 40px 0;
  min-height: 100vh;
  background-color: #f5f6f8;
  border-right: 1px solid #efe9ed;
}
.ui.button {
  border-radius: 0 !important ;
}
.plr {
  padding: 0 20px;
}

/* colors */
.primaryColor {
  color: #718486;
}

.h100 {
  height: 100%;
}
.pd20 {
  padding: 20px;
}

.overflowAuto {
  overflow: auto;
}

.flex {
  display: flex;
}

.flexWrap {
  flex-wrap: wrap;
}

.flexSpaceBetween {
  justify-content: space-between;
}
.mrRight10 {
  margin-right: 10px;
}
.mrBottom20 {
  margin-bottom: 20px;
}

.w300px {
  width: 300px;
}


.showScrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  background-color: #f5f5f5;
}

.showScrollbar::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}

.showScrollbar::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ffc82d;
}

.mt40 {
  margin-top: 30px;
}

.w120 {
  width: 120px;
}
.ml10 {
  margin-left: 10px;
}

.w220 {
  width: 220px !important;
}
.w100px {
  width: 100px !important;
}
.w160 {
  width: 160px !important;
}
.w250 {
  width: 250px !important;
}
.w280 {
  width: 280px !important;
}
.positionRelative {
  position: relative;
}

.lable {
  font-size: 12px;
  color: #999;
  text-transform: uppercase;
  margin-bottom: 5px;
  display: block;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.filter input {
  background: #ebebeb !important;
  left: 0 !important;
  color: #000;
}

.submitButton {

  background: #212121 !important;
  text-transform: uppercase !important;
  color: #fff !important;
}

.height100vh {
  height: 100vh;
}
.overflowX {
  overflow-x: hidden;
}
.overflowY {
  overflow-y: hidden;
}


 /* autosuggest__suggestions */
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container{
  border-bottom-left-radius: 0;
  height: 280px;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  position: absolute;
  top: 51px;
  width: 300px;
  margin: 0;
  padding: 0;
 background: #fff;
  border-radius: 5px;
  list-style-type: none;
  border: none!important;
  box-shadow: none!important;
  cursor: text;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255,255,255,0);
  padding: inherit;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  overflow-y: scroll;
}



.react-autosuggest__suggestions-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  background-color: #f5f5f5;
}

.react-autosuggest__suggestions-container::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}

.react-autosuggest__suggestions-container::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ffc82d;
}

.react-autosuggest__suggestions-list{
  padding: 0;
  margin: 0;
  list-style: none;
}
.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 4px 20px; font-family: "Lato", sans-serif;
  font-size: 12px;font-weight: 600;
  line-height: 1.3rem;
  display: inline-block;
  width: 100%;

}

.react-autosuggest__suggestion:hover {
background-color: #999;
color: #fff;
}

.react-autosuggest__suggestion--focused {
  background-color: #ddd;    color: #fff;
}

.errorMess{
  font-size: 11px;
  color: red;
  padding: 5px 0;
  text-align: left;
}

.emptyTitle{
  font-size: 18px;
  color: #999;
  display: flex;
 
}
.flexEnd{
  justify-content: flex-end;
}
.showDateTitel{
  color: #718496;
  font-size: 24px;
}
.filterTitleInfo{
  color: #718496;
  font-size: 24px;
  margin-bottom: 25px;
  cursor: pointer;
}
.emptyTitle svg{margin-right: 10px ;}

.showfilterBox{
  margin-bottom: 40px;
  padding: 20px 0;
  border-bottom: 1px solid #f5f6f8;
}

.dropdownTrigger{
  float: right;
  margin-top: 10px;
  
  text-align: right;
 
}
.dropdownTrigger .icon{
  background: #fff !important;
    border: 1px solid #ccc !important;font-size: 12px !important;
    color: #ccc !important;
    padding: 5px 10px 5px 0px !important;
}

.dropdownTrigger .icon i{
  background: #fff !important;
border: none !important;

}
.popcontent ul{
  padding: 0 20px;
  margin: 0;
  list-style-type: none;
}

.popcontent ul li{
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 0px;
  
}
.popcontent ul li svg{
 margin-right: 10px;
  
}
.error{
  text-align: left;
  font-size: 12px;
  color: red;
}

.smallInput input{
  width: 100px;
  background: #fff !important;
}

.favourites{ 
  margin-top: 20px;
  padding: 10px 10px 10px 40px;
}
.favourites .showfilterBox{
  margin-bottom: 40px;
  padding: 20px;
  border: 1px solid #ccc;
}

.favourites .filterTitleInfo{
color: #718496;
text-decoration: underline;
}

.favourites .showDateTitel{
  border:none;
  color: #718496;
  }
  
  .favourites .popcontent ul{
width: 85px !important;
padding: 0 !important;
  }
  .favourites .popcontent ul li{
    text-transform: uppercase !important;
      }
      .popcontent ul li:hover{
       color: #ffc82d !important;
          }
  .favourites .showDateTitel .dropdownTrigger{
    margin-top: -10px;
  }
  .favourites .showDateTitel .icon{
  border: none !important;
  }
  .favouritesTitle{
    display: flex;
    text-transform: uppercase;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 16px;
    color: #999;
  }

  .dropdownTriggerHeader .icon{
    border: none !important;
    background: none !important;
    

    }
    .dropdownTriggerHeader button{
      border: none !important;
      background: none !important;
      width: 95px;
      font-weight: 600;
  
      }
    .dropdownTriggerHeader{
      margin-top: 0px;
      background-color: none;
    }

  .pointer{
    cursor: pointer;
  }
  label span{
    color: red;
  }

  .lodding{
    margin-top: 40px;
    opacity: 0.5;
    width: 100%;
    
   
  }
  
  .lodding .innerLodding{
    display: flex;
 align-items: baseline;
  }

  .lodding .innerLodding .segment{
    flex: 50% 1;
    padding: 10px;
    margin-bottom: 50px;
    margin-right: 50px;
  }
  .lodding img{
    height: 30px;
    width: 100%;
    margin-bottom: 10px;
  }

  .iconDate {
   background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAcCAYAAAB/E6/TAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NUJCMzM4QTBDNkQ5MTFFQjhCNUNBNDUzRTFCOEMyMUMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NUJCMzM4QTFDNkQ5MTFFQjhCNUNBNDUzRTFCOEMyMUMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1QkIzMzg5RUM2RDkxMUVCOEI1Q0E0NTNFMUI4QzIxQyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1QkIzMzg5RkM2RDkxMUVCOEI1Q0E0NTNFMUI4QzIxQyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pgjq2A4AAAHjSURBVHja5JZJKEZRFICfn4yZpw1L2SAs9VN2LBRLRYmVPQsrC2XDio0FFiwMK0OSDWUoZWFKSpFChhT6DUmG79T763a773+/4bFw6uvUuefdc9859557IywHKff741EXULSyunpshRB8Y1GXUIrvocnHF+L7aEiEFMtdJFCS7W+UCFYThW6HGohXxiKhAA7gySWQk+8jzEKPBOmFRuiDO8UpDrphBs5cAjn5JkMbZMsfyQqaye2YlndJ2Q2UMLblUiNHX8bqUcM+O7/7hu8fYBpOw6hRAKbg3DAmc8dGOX3Jyl5QtWEEEd9XVF0on2CgfH7R8kjy1UDjlscSDFQBux7FKITlYKAAeb71IgolCbh1hh8Vn2EFVdCh2fJgwO4iQVscDEKO5tsFFeH0OulX6YaTnyUtS2s7mfY5VEVsCaZe9x7O6f9GjYpRm39Xo9/cDK0wodnKYAdi1EYKe1Co+c5Dg9OBVWVDuy5EjmAEXhTbPYwarpBJ2P6Hm0EOJSQa7GkGW+p3dl0nLGkTVsuLSLqBYpMDfI32fzVQPzRptkWopI5PymV3JTZY/8w1YWkTXGm2Z9SawXf5M6l7gwwP94HM/SbbW95dufaz6NqDIPKcO5HUNcMQLHjQkiRbc9DyIcAAI1qV4bjDnFwAAAAASUVORK5CYII=) !important;
   background-position: 95% 50%!important;
   background-repeat: no-repeat !important;
   background-size: 14% !important;
}
  

.favouriteLeft .filterBox{
  background: none !important;
}

.favouriteLeft .listPanal{
  margin-top: 20px;
}

.colorfff{
  color: #fff !important;
}

.overLayer .ui.segment{
  width: 150px;
  box-shadow: none;
  background: none !important;    border: none;
}
.overLayer .ui.inverted.dimmer{
  background: none !important;
}

.overLayer .ui.inverted.dimmer .ui.loader:after{
  border-color: #fff transparent transparent;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.searchBox input {
  background: #fff !important;
  left: 0 !important;
  color: #000;
  width: 100%;
  outline: 0;
  transform: rotateZ(0);
  min-width: 14em;
  min-height: 2.71428571em;
  display: inline-block;
  padding: 0.78571429em 2.1em 0.78571429em 1em;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, width 0.1s ease;
  font-size: 14px;
}
.searchBox input::-webkit-input-placeholder {
  font-size: 11px;
  color: #ccc;
}
.title {
  font-size: 10px;
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #999;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.smallTitle {
  font-size: 12px;
  margin-bottom: 5px;
  color: #999;
  text-transform: uppercase;
}
::-webkit-input-placeholder {
  font-size: 11px;
  color: #ccc;
}
.tagInner {
  display: inline-block;
  margin-right: 10px;
}
.companiesTag {
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  background: #ebebeb;
  font-size: 12px;
  color: #666666;
  padding: 5px;
  align-items: center;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}
.companiesTag p {
  padding-right: 10px;
  margin-bottom: 0;
  line-height: 1rem;
}
.companiesTag span {
  width: 20px;
}
.filter .multiple .ui.label {
  font-size: 14px !important;
  border: 1px solid #f5f6f8;
  background: #fff;
  font-weight: 400;
}

.showcompanyList {
  margin: 60px 0 10px 0;
}
.showcompanyList h4 {
  margin: 0;
}
.showcompanyList h4 {
  margin-bottom: 0px;
  text-transform: uppercase;
  color: #999;
}
.showcompanyList h4 span {
  color: #000;
}
.showcompanyList p {
  font-size: 11px;
  color: #999;
}
.showcompanyList input {
  background: #ebebeb !important;
  border-radius: 0 !important;
  border: 1px solid #f5f6f8 !important;
}
.showcompanyList .listPanal {
  max-height: 300px;
  background: #ebebeb;
  padding: 10px 6px;
  border-radius: 5px;
}
.showcompanyList .listItem {
  display: flex;
  margin-top: 6px;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 12px;
  line-height: 1rem;
}
.showcompanyList .listItem .name {
  width: 90%;
  padding: 4px 10px;
  display: inline-block;
}
.showcompanyList .listItem span {
  padding: 4px 10px;
}
.showcompanyList .active {
  background: #fff !important;
  color: #000 !important;
}
.runReports {
  background: #ffc82d !important;
  text-transform: uppercase !important;
  color: #000 !important;
}

.searchBox input {
  background: #fff !important;
  left: 0 !important;
  color: #000;
  width: 100%;
  outline: 0;
  transform: rotateZ(0);
  min-width: 14em;
  min-height: 2.71428571em;
  display: inline-block;
  padding: 0.78571429em 2.1em 0.78571429em 1em;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, width 0.1s ease;
}
.searchBox input::-webkit-input-placeholder {
  font-size: 11px;
  color: #ccc;
}
.title {
  font-size: 10px;
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #999;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.smallTitle {
  font-size: 12px;
  margin-bottom: 5px;
  color: #999;
  text-transform: uppercase;
}
.companiesTag {
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  background: #ebebeb;
  font-size: 12px;
  color: #666666;
  padding: 5px;
  align-items: center;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}
.companiesTag p {
  padding-right: 10px;
  margin-bottom: 0;
  line-height: 1rem;
}
.companiesTag span {
  width: 20px;
}
.growthrate .ui[class*="right labeled"].input > input {
  border-top-right-radius: 0.28571429rem !important;
  border-bottom-right-radius: 0.28571429rem !important;
  border-right-color: rgba(34, 36, 38, 0.15) !important;
}
.growthrate .label.label {
  border: none !important;
  background: #f5f6f8 !important;
}
.insightplus .showcompanyList {
  padding: 0 !important;
  margin-top: 30px !important;
}
.insightplus .showcompanyList h4 {
  margin: 0;
}

.searchBox input {
  background: #fff !important;
  left: 0 !important;
  color: #000;
  width: 100%;
  outline: 0;
  transform: rotateZ(0);
  min-width: 14em;
  min-height: 2.71428571em;
  display: inline-block;
  padding: 0.78571429em 2.1em 0.78571429em 1em;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, width 0.1s ease;
}
.searchBox input::-webkit-input-placeholder {
  font-size: 11px;
  color: #ccc;
}
.title {
  font-size: 10px;
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #999;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.smallTitle {
  font-size: 12px;
  margin-bottom: 5px;
  color: #999;
  text-transform: uppercase;
}
.companiesTag {
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  background: #ebebeb;
  font-size: 12px;
  color: #666666;
  padding: 5px;
  align-items: center;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}
.companiesTag p {
  padding-right: 10px;
  margin-bottom: 0;
  line-height: 1rem;
}
.companiesTag span {
  width: 20px;
}
.growthrate .ui[class*="right labeled"].input > input {
  border-top-right-radius: 0.28571429rem !important;
  border-bottom-right-radius: 0.28571429rem !important;
  border-right-color: rgba(34, 36, 38, 0.15) !important;
}
.growthrate .label.label {
  border: none !important;
  background: #f5f6f8 !important;
}
.insightplus .showcompanyList {
  padding: 0 !important;
  margin-top: 30px !important;
}
.insightplus .showcompanyList h4 {
  margin: 0;
}



/* Table CSS */

  table {
    border-spacing: 0;
    border: 1px solid rgba(34,36,38,.15);
    margin-top: 20px;
    border-collapse: collapse;
    width: 100%;
    tr {
      th,
      td {
        margin: 0;
        padding: 0.5rem;
        border-bottom: 1px solid rgba(34,36,38,.15);
        border-right: 1px solid rgba(34,36,38,.15);
        min-width: 125px;
      }
      > :first-child {
        max-width: -webkit-max-content;
        max-width: max-content;
        min-width: 40px;
        text-align: center;
      }
    }
  }
  .p-5 {
    padding: 20px;
  }
  .p-2{
    padding: 8px;
  }
  .border{
    border: 1px solid rgba(34,36,38,.15);
  }
  .rounded{
    border-radius: 4px;
  }
  .filter-input{
    border: 1px solid rgba(34, 36, 38, .15);
    border-radius: 4px;
    padding: 2px 4px;
    margin-top: 4px;
    width: 100%;
  }

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem* var(--tw-space-x-reverse));
  margin-left: calc(0.5rem* calc(1 - var(--tw-space-x-reverse)));
}

.gap-1 {
  grid-gap: 0.25rem;
  gap: 0.25rem;
}
.gap-2 {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.items-center {
  align-items: center;
}
.mt-5, .ui.button.mt-5 {
  margin-top: 1.25rem;
}
.pagination button{
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 4px;
  cursor: pointer;
}

.pagination select {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 4px;
  padding: 2px 4px;
}
.overflow-auto {
  overflow: auto;
}
.text-gray-400{
  color: rgb(156 163 175);
}
.justify-between {
  justify-content: space-between;
}
.w40 {
  width: 40%;
}
.w20 {
  width: 20%;
}
.w10 {
  width: 10%;
}
.w30 {
  width: 30% !important;
}
f18 {
  font-size: 18px;
}
.reportsItem {
  display: inline-block;
  text-align: center;
}
.marginLeftNav {
  margin-left: -15px;
}
.reports {
  padding: 40px ;
  color: #718496;
  width: 100%;
  display: inline-block;
  font-size: 14px;
}
.reports .report {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  background: #ffffff;
  box-shadow: rgba(28, 28, 28, 0.08) 0px 2px 8px;
  border: 1px solid #e8e8e8;
  padding: 5px 20px;
  justify-content: space-between;
}
.reports .report svg {
  margin-right: 10px;
}
.reports .report .ptb {
  padding: 5px 0;
}
.reports .report .name {
  text-align: left;
  cursor: pointer;
  color: #000;
  text-decoration: underline;
}
.reports .report:hover {
  border: 1px solid #000;
}
.reports i {
  font-size: 24px;
  cursor: pointer;
}
.borderLeft {
  border-right: 1px solid #ccc;
}
.justifyContent {
  justify-content: center;
}
.icon {
  text-align: end;
}

