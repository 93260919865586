.w40 {
  width: 40%;
}
.w20 {
  width: 20%;
}
.w10 {
  width: 10%;
}
.w30 {
  width: 30% !important;
}
f18 {
  font-size: 18px;
}
.reportsItem {
  display: inline-block;
  text-align: center;
}
.marginLeftNav {
  margin-left: -15px;
}
.reports {
  padding: 40px ;
  color: #718496;
  width: 100%;
  display: inline-block;
  font-size: 14px;
}
.reports .report {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  background: #ffffff;
  box-shadow: rgba(28, 28, 28, 0.08) 0px 2px 8px;
  border: 1px solid #e8e8e8;
  padding: 5px 20px;
  justify-content: space-between;
}
.reports .report svg {
  margin-right: 10px;
}
.reports .report .ptb {
  padding: 5px 0;
}
.reports .report .name {
  text-align: left;
  cursor: pointer;
  color: #000;
  text-decoration: underline;
}
.reports .report:hover {
  border: 1px solid #000;
}
.reports i {
  font-size: 24px;
  cursor: pointer;
}
.borderLeft {
  border-right: 1px solid #ccc;
}
.justifyContent {
  justify-content: center;
}
.icon {
  text-align: end;
}
